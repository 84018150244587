import styled from "styled-components"
import media from 'styled-media-query'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

export const PostItemLink = styled(AniLink)`
  color: var(--texts);
  display: flex;
  text-decoration: none;
  justify-content: center;
  &:hover {
    color: var(--highlight);
  }
`

export const PostItemWrapper = styled.section`
  display: flex;
  align-items: center;
  padding: 2rem 3rem;
  border-bottom: 1px solid var(--borders);
  width: 65%;

  ${media.lessThan("large")`
    padding: 0;
    width: 100%;
    flex-direction: column;
  `}
`

export const PostItemTag = styled.div`
  align-items: center;
  background: ${props =>
    props.background ? props.background : "var(--highlight)"};
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 1.3rem;
  font-weight: 700;
  justify-content: center;
  min-height: 90px;
  min-width: 90px;
  text-transform: uppercase;
  ${media.lessThan("large")`
    align-self: flex-start;
    border-radius: 0;
    min-height: 25px;
    min-width: 50px;
  `}
`

export const PostItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1.5rem;
  
  ${media.lessThan("large")`
    padding-top: 1rem;
    width: 100%;
  `}
`

export const PostItemDate = styled.time`
  font-size: 0.9rem;
`

export const PostItemTitle = styled.h1`
  font-size: 1.6rem;
  font-weight: 700;
  margin: 0.2rem 0 0.5rem;
`

export const PostItemDescription = styled.p`
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.2;

  ${media.lessThan("large")`
    padding-bottom: 1rem;
  `}
`
