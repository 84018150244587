import styled from 'styled-components'

export const ProfileWrapper = styled.section`
  color: var(--texts);
  display: flex;
  flex-direction: column;
  padding-top: 5rem;
`

export const ProfileAuthor = styled.h1`
  font-size: 1.6rem;
  margin: 0.5rem auto 1.5rem;
  text-align: center;

`

export const ProfilePosition = styled.small`
  display: block;
  font-size: 1.2rem;
  font-weight: 300;
  padding-top: 0.5rem;
`

export const ProfileDescription = styled.small`
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.4;
  text-align: center; 

`
