import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Avatar from '../Avatar';
import * as S from './styled'

const query = graphql`
  query SiteMetadata {
    site {
      siteMetadata {
        title
        description
        author
        position
      }
    }
  }    
`

const Profile = () => {
  const { 
    site: {
      siteMetadata: { author, position, description }
    },
  } = useStaticQuery(query);
  return (
    <S.ProfileWrapper>
    </S.ProfileWrapper>
  )
}

export default Profile;
