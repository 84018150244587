import styled from 'styled-components'

export const PaginationWrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 3rem;
  color: var(--texts);

  p {
    padding: 0rem 2rem;
  }

  a {
    color: var(--texts);
    text-decoration: none;
    transition: color 0.5s;
    &:hover {
      color: var(--highlight);
    }
  }

`
